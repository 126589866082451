// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-courses-and-tours-js": () => import("./../../../src/pages/en/courses-and-tours.js" /* webpackChunkName: "component---src-pages-en-courses-and-tours-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-no-kurs-og-aktiviteter-js": () => import("./../../../src/pages/no/kurs-og-aktiviteter.js" /* webpackChunkName: "component---src-pages-no-kurs-og-aktiviteter-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-tour-js": () => import("./../../../src/templates/tour.js" /* webpackChunkName: "component---src-templates-tour-js" */)
}

